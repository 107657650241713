<template>
  <v-sheet
    color="white"
    elevation="0"
    height=""
    rounded="lg"
    class="px-6 py-5 mb-2"
  >
    <v-row justify="space-between" class="d-flex">
      <v-col class="text-left overline">
        <span>Complete your profile</span></v-col
      >
      <v-col class="text-right"
        ><span style="border-radius: 5px" class="pa-2 accent"
          >{{ profileComplete }}/2</span
        ></v-col
      >
    </v-row>
    <v-card
      rounded="lg"
      v-for="status in profileStatus"
      :key="status.title"
      class="my-4 pa-2"
      flat
    >
      <template v-if="!status.inActive">
        <v-row align="center" justify="center">
          <v-col cols="2">
            <v-icon large :color="status.status ? 'green' : 'accent'">{{
              status.status ? 'mdi-account-check' : 'mdi-account-question'
            }}</v-icon>
          </v-col>
          <v-col cols="10">
            <span class="profile-title">
              {{ status.title }}
            </span>
            <p class="mb-2 profile-desc">{{ status.desc }}</p>
          </v-col>
        </v-row>
      </template>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'
import { COUNTRIES } from '../../utils/countryHelpers'

export default {
  name: 'profileStatus',
  computed: {
    ...mapState({
      user: (state) => state.user.user
    }),
    profileComplete() {
      return !!this.user.email_verify + this.user.kycverify
    },
    profileStatus() {
      if (this.user) {
        return [
          {
            title: 'Confirm your email',
            desc: 'Add basic account security',
            status: this.user.email_verify,
            inActive: false
          },
          {
            title: 'KYC',
            desc: 'Upload your documents, get verified',
            status: this.user.kycverify,
            inActive:
              this.userCountry === COUNTRIES.GH ||
              this.userCountry === COUNTRIES.KE
          }
          // {
          //   title: 'Add BVN',
          //   desc: 'Link BVN to account',
          //   status: !!this.user.bvnVerified
          // }
        ]
      }
      return []
    }
  },
  data: () => ({})
}
</script>

<style>
.profile-title {
  font-size: 1.2rem;
}
.profile-desc {
  font-size: 14px;
  font-weight: 200;
}
</style>
