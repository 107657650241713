<template>
  <v-sheet
    color="white"
    elevation="0"
    height=""
    rounded="lg"
    class="px-6 py-5 animate__animated animate__zoomIn"
  >
    <h4 class="text-overline text--grey text--lighten-2">Statistics</h4>
    <div v-for="statistic in statistics" :key="statistic.title" class="p-2">
      <v-row justify="center">
        <v-col cols="3">
          <v-img width="45" :src="statistic.icon"></v-img>
        </v-col>
        <v-col cols="9">
          <span class="statistics-title">
            {{ statistic.title }}
          </span>
          <h2 class="text-h4">{{ statistic.count }}</h2>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'Statistics',
  props: ['statistics']
}
</script>

<style scoped>
.statistics-title {
  font-size: 0.8rem;
  font-weight: 200;
  text-transform: uppercase;
}
</style>
