<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :elevation="hover ? '1' : '0'"
      color="#fff"
      class="text-center px-2 py-4 animate__animated animate__zoomIn h-100 h-auto"
      rounded="lg"
      :disabled="disabled"
    >
      <v-col class="h-100 text-center">
        <v-img
          class="mb-10 mx-auto"
          width="115"
          height="115"
          :src="image"
          alt="card"
        ></v-img>
        <p style="font-size: 18px" class="mb-4 font-weight-regular">
          {{ title }}
        </p>
        <v-card-subtitle v-if="disabled">
          <span style="font-size: 14px">{{ message }}</span>
        </v-card-subtitle>
        <v-btn :to="link" color="" rounded class="accent">{{
          btnTitle ? btnTitle : 'Trade Now'
        }}</v-btn>
      </v-col>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'Mini-card',
  props: {
    title: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    btnTitle: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    message: {
      type: String,
      required: false
    }
  },
  methods: {}
}
</script>

<style></style>
