<template>
  <v-container class="pb-10">
    <div class="d-block d-md-flex justify-lg-space-between">
      <div>
        <h2 class="text-h4 text--primary">Hello, {{ user.username }}</h2>
        <p class="text-subtitle-2 font-weight-regular">
          Welcome to your dashboard
        </p>
        <p class="text-subtitle-1 font-weight-bold">
          Got questions? Call or Whatsapp {{ siteData.phone }}
        </p>
      </div>
      <div class="ml-md-16">
        <v-row justify="center" justify-md="end">
          <template v-for="menu in menus">
            <v-hover
              class="my-2"
              v-slot="{ hover }"
              v-if="
                !menu.disabled.includes(userCountry) && menu.status !== false
              "
              :key="menu.title"
            >
              <v-btn
                outlined
                rounded
                text
                :to="menu.href"
                :color="hover ? 'primary' : ''"
                :class="`mx-1`"
              >
                <v-icon color="accent" left>
                  {{ menu.icon }}
                </v-icon>
                {{ menu.title }}
              </v-btn>
            </v-hover>
          </template>
        </v-row>
      </div>
    </div>
    <v-row>
      <v-col cols="12" md="8">
        <v-card flat>
          <v-card-text class="font-weight-medium">
            For every transaction, Our Platform will send you email
            notifications on your sell or buy order. Always check your email for
            order updates. Should in case we have urgent information, we will
            use your phone number to call you. do not forget to click on the
            <router-link
              to="/user/settings"
              class="text-decoration-underline primary--text"
              >setting</router-link
            >
            to update your valid phone number.
          </v-card-text>
        </v-card>

        <v-sheet class="px-5 py-2 mt-5" color="#FFF" rounded="lg" elevation="0">
          <v-row>
            <v-col cols="12">
              <v-sheet
                rounded="lg"
                color="primary"
                elevation="0"
                class="full-width py-4 pb-5 white--text px-5 animate__animated animate__slideInLeft darken-1"
              >
                <v-row justify="space-between">
                  <v-col cols="7">
                    <span class="text-h6">Refer a friend</span>
                    <p class="subtitle-1">
                      Make money by inviting friends to trade on Snappy Exchange
                    </p>

                    <v-row class="">
                      <v-btn
                        to="/user/referral-system"
                        class="mr-2 mb-2"
                        depressed
                        elevation="0"
                        >Start Earning</v-btn
                      >
                      <v-btn
                        v-scroll-to="{
                          el: '#top-traders',
                          offset: -55
                        }"
                        class="mb-2"
                        depressed
                        color="accent"
                        elevation="0"
                      >
                        See top Traders</v-btn
                      >
                    </v-row>
                  </v-col>
                  <v-col cols="4" style="position: relative">
                    <v-img
                      :src="referralImage"
                      style="position: absolute; right: 0; top: 0"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row align="stretch" class="my-2">
            <template v-for="card in cards">
              <v-col
                :key="card.title"
                v-if="!card.disabled.includes(userCountry)"
                cols="12"
                md="6"
              >
                <mini-cards
                  :link="card.href"
                  :image="card.image"
                  :title="card.title"
                  :btnTitle="card.btnTitle"
                  :disabled="card.status === false"
                  :message="card.message"
                />
              </v-col>
            </template>
          </v-row>
        </v-sheet>
        <v-sheet rounded="lg" elevation="0">
          <v-col class="my-4 pa-10">
            <v-col class="overline text-left">Recent Transactions</v-col>
            <div v-if="transactions.length > 0">
              <v-data-table :items="transactions" :headers="headers">
                <template v-slot:[`item.amount`]="{ item }">
                  <span>{{ item.amount.price }}</span>
                  <span class="overline"> {{ item.amount.currency }}</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-card
                    :color="
                      item.status == 'approved'
                        ? 'success'
                        : item.status == 'pending'
                        ? 'warning'
                        : 'error'
                    "
                    flat
                    rounded="md"
                    outlined
                    class="text-capitalize text-center white--text pa-1"
                  >
                    {{ item.status }}
                  </v-card>
                </template>
              </v-data-table>
            </div>

            <no-transaction v-if="transactions.length === 0" />
          </v-col>
        </v-sheet>
        <v-sheet
          class="px-5 py-10"
          rounded="lg"
          color="secondary"
          elevation="0"
        >
          <h3>Download Our Mobile App</h3>
          <p>
            Download our mobile app to get instant access to the best trading
            experience.
          </p>
          <div class="d-flex justify-start align-center mt-10">
            <a
              class="d-block mr-5"
              href="https://bit.ly/3s4zABm"
              target="_blank"
            >
              <img
                width="100%"
                src="../../assets/img/store-android.svg"
                alt="download on android"
              />
            </a>
            <a class="d-block" href="https://bit.ly/3s4zABm" target="_blank">
              <img
                width="100%"
                src="../../assets/img/store-ios.svg"
                alt="download on ios"
              />
            </a>
          </div>
        </v-sheet>
      </v-col>
      <v-col id="top-traders" cols="12" md="4">
        <leaderboards />
        <statistics class="mb-4" :statistics="statistics" />
        <profile-status />
      </v-col>
    </v-row>

    <v-overlay v-model="downloadApp" opacity="0.8">
      <v-dialog max-width="700" v-model="downloadApp">
        <v-card>
          <v-container>
            <div class="d-flex align-center">
              <v-card-title>
                <h5>Download Our Mobile App</h5>
              </v-card-title>
              <v-spacer />
              <v-icon @click="downloadApp = false"> mdi-close</v-icon>
            </div>
            <v-card-subtitle
              >Experience the best of Snappy Exchange</v-card-subtitle
            >
            <div>
              <v-row>
                <v-col style="text-align: center" cols="12" md="6">
                  <img
                    style="margin-inline: auto; width: 80%"
                    class="align-center"
                    src="../../assets/img/mockup_mobile.png"
                    alt="get our app"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-card-subtitle>
                    Download our mobile app and enjoy the best of Snappy
                    Exchange
                  </v-card-subtitle>
                  <div class="d-flex justify-space-between align-center mt-2">
                    <a
                      class="d-block"
                      href="https://bit.ly/3s4zABm"
                      target="_blank"
                    >
                      <img
                        width="100%"
                        src="../../assets/img/store-android.svg"
                        alt="download on android"
                      />
                    </a>
                    <a
                      class="d-block"
                      href="https://bit.ly/3s4zABm"
                      target="_blank"
                    >
                      <img
                        width="100%"
                        src="../../assets/img/store-ios.svg"
                        alt="download on ios"
                      />
                    </a>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card>
      </v-dialog>
    </v-overlay>
  </v-container>
</template>

<script>
import Statistics from '../../components/dashboard/statistics'
import profileStatus from '../../components/dashboard/profileStatus'
import MiniCards from '../../components/dashboard/cards/miniCards.vue'
import NoTransaction from '../../components/noTransaction.vue'

import referralImage from '../../assets/img/referral-image.png'
import card from '../../assets/img/cards.341766b6.png'
import cryto from '../../assets/img/cryto.png'
import PayBillImage from '../../assets/img/pay-your-bills.jpeg'
import AirtimeToCashImage from '../../assets/img/airtime-to-cash.jpeg'

import noData from '../../assets/img/no_data.svg'
import paidTrans from '../../assets/img/paid-trans.svg'
import pending from '../../assets/img/pending-trans.svg'
import wallet from '../../assets/img/wallet-stat.svg'
import decline from '../../assets/img/declined-trans.svg'

import { mapState } from 'vuex'
import transactionApi from '../../api/users/transactions'
import profileApi from '../../api/users/profile'
import { numberWithCommas, updateWallet } from '../../utils/helpers'

import dataApi from '../../api/systemData'
import Leaderboards from '../../components/leaderboards.vue'
import { COUNTRIES } from '../../utils/countryHelpers'

export default {
  components: {
    Statistics,
    profileStatus,
    MiniCards,
    NoTransaction,
    Leaderboards
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      wallet: (state) => state.user.wallet,
      siteData: (state) => state.user.siteSettings
    }),

    menus() {
      return [
        {
          title: 'Sell Giftcards',
          icon: 'mdi-wallet-giftcard',
          href: '/user/products/giftcards',
          disabled: ''
        },
        {
          title: 'Trade E-Currency',
          icon: 'mdi-currency-btc',
          href: '/user/products/crypto',
          disabled: ''
        },
        {
          title: 'Bill Payment',
          icon: 'mdi-card',
          href: '/user/products/bill-payment',
          disabled: [COUNTRIES.GH, COUNTRIES.KE]
        },
        {
          title: 'Convert Airtime to Cash',
          icon: 'mdi-card',
          href: '/user/products/bills/convert-airtime/',
          disabled: [COUNTRIES.GH, COUNTRIES.KE],
          status: this.siteData.airtimeToCashFeatureAvailable
        }
      ]
    },

    cards() {
      return [
        {
          image: card,
          title: 'Giftcards',
          href: '/user/products/giftcards',
          btnTitle: 'Sell Now',
          disabled: [],
          status: true
        },
        {
          image: cryto,
          title: 'Cryptocurrency',
          href: '/user/products/crypto',
          disabled: [],
          status: true
        },
        {
          image: PayBillImage,
          title: 'Pay Bills',
          href: '/user/products/bill-payment',
          btnTitle: 'Pay Now',
          disabled: [COUNTRIES.GH, COUNTRIES.KE],
          status: true
        },
        {
          image: AirtimeToCashImage,
          title: 'Airtime to Cash',
          href: '/user/products/bills/convert-airtime/',
          btnTitle: 'Convert Now',
          disabled: [COUNTRIES.GH, COUNTRIES.KE],
          status: this.siteData.airtimeToCashFeatureAvailable,
          message: this.siteData.airtimeToCashFeatureMessage,
        }
      ]
    },

    profileComplete() {
      return !!this.user.email_verify + this.user.kycverify
    },
    statistics() {
      if (this.transactions) {
        return [
          {
            title: 'Pending Transactions',
            count: this.transactions.filter((t) => t.status === 'pending')
              .length,
            icon: pending
          },
          {
            title: 'Paid Transactions',
            count: this.transactions.filter((t) => t.status === 'approved')
              .length,
            icon: paidTrans
          },
          {
            title: 'Declined Transactions',
            count: this.transactions.filter((t) => t.status === 'declined')
              .length,
            icon: decline
          },
          {
            title: 'Amount in Wallet',
            count: numberWithCommas(this.wallet.balance) || 0,
            icon: wallet
          }
        ]
      }
      return []
    }
  },
  created() {
    this.getTransactions()
    updateWallet()
    this.$store.dispatch('user/getSiteSettings')
  },
  async mounted() {
    const profile = await profileApi.profile().get()

    this.$store.commit('user/updateUserData', profile.data.data)
  },
  methods: {
    async getData() {
      const res = await dataApi.data().siteSettings('user')
      this.data = res.data.data
    },

    async getTransactions() {
      const res = await transactionApi.transactions().getAll([], 3)
      this.transactions = res.data.data.data
        .map((d) => ({
          ...d,
          date: new Date(
            d.created_at ? d.created_at : d.createdAt
          ).toDateString(),
          type: d.type,
          amount: {
            price: numberWithCommas(d.main_amo),
            currency: d.currencyCode
          },
          status: d.status
        }))
        .slice(0, 4)
    }
  },

  data: () => ({
    numberWithCommas,
    downloadApp: true,
    transactions: [],
    noData,
    referralImage,
    // card,
    model: null,
    headers: [
      {
        text: 'Type',
        value: 'type'
      },
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Amount',
        value: 'amount'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ]
  })
}
</script>
