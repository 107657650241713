<template>
  <div>
    <v-card
      color="white"
      class="mb-5 pb-10 full-width py-4 px-5 animate__animated animate__slideInRight darken-2"
      elevation="0"
      rounded="lg"
    >
      <h4 class="mb-5">Top Traders</h4>
      <div class="leaderboard-tabs">
        <div
          @click="switchTypeTab(0)"
          :class="`${leaderboardTab === 0 && 'active'} leaderboard-tab`"
        >
          Giftcard
        </div>
        <div
          @click="switchTypeTab(1)"
          :class="`${leaderboardTab === 1 && 'active'} leaderboard-tab`"
        >
          Crypto S
        </div>
        <!-- <div
          @click="switchTypeTab(2)"
          :class="`${leaderboardTab === 2 && 'active'} leaderboard-tab`"
        >
          Crypto P
        </div> -->
      </div>
      <div class="leaderboard-tabs-period">
        <div
          @click="switchSelectionTab(0)"
          :class="`${selection === 0 && 'active'} leaderboard-tab-period`"
        >
          Day
        </div>
        <div
          @click="switchSelectionTab(2)"
          :class="`${selection === 2 && 'active'} leaderboard-tab-period`"
        >
          Week
        </div>
        <div
          @click="switchSelectionTab(1)"
          :class="`${selection === 1 && 'active'} leaderboard-tab-period`"
        >
          Month
        </div>
      </div>

      <div class="leading-group">
        <div
          v-for="(data, i) in topThree"
          :key="i"
          :class="`leading-${i + 1} leading-tabs`"
        >
          <span class="position position_absolute">{{ i + 1 }}</span>
          <v-avatar
            :color="i === 0 ? 'amber' : i === 1 ? 'blue-grey' : 'blue'"
            :size="i === 0 ? 105 : 75"
            >{{ data.firstName | getInitials }}
          </v-avatar>
          <p class="money">
            {{ getCurrencySymbol(userCountry.toLowerCase()) }}
            {{ data.amount | formatAmount }}
          </p>
          <p class="name">{{ data.username | trucateUsername }}</p>
        </div>
      </div>

      <v-card
        elevation="0"
        v-if="otherUsers.length === 0 && topThree.length === 0 && !loadingData"
        class="text-center"
      >
        <h3>No user on leaderboards yet</h3>
        <p>
          Be the first,
          <router-link to="/user/products/giftcards">Sell Giftcard</router-link>
          or <router-link to="/user/products/crypto">Trade Crypto</router-link>
        </p>
      </v-card>

      <v-card elevation="0" :loading="loadingData">
        <v-virtual-scroll
          :items="otherUsers"
          :item-height="60"
          :height="otherUsers.length > 0 ? '220' : '150'"
        >
          <template v-slot:default="{ item }">
            <div class="d-flex w-100 justify-space-between align-center">
              <div class="d-flex">
                <div class="mr-3">
                  <v-avatar :color="getColorBg()" :size="50">{{
                    item.firstName | getInitials
                  }}</v-avatar>
                </div>
                <div>
                  <h4
                    class="text-capitalize font-weight-bold text-body-1 primary--text"
                  >
                    {{ getCurrencySymbol(userCountry.toLowerCase())
                    }}{{ item.amount | formatAmount }}
                  </h4>
                  <p :title="item.username" class="subtitle-2">
                    {{ item.username | trucateUsername }} .
                    {{ item.firstName | getInitials }}
                  </p>
                </div>
              </div>
              <div class="text-body-2 text-right position">
                {{ item.position }}
              </div>
            </div>
          </template>
        </v-virtual-scroll>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dataApi from '../api/systemData'
import { getCurrencySymbol } from '../utils/countryHelpers'
import { numberWithCommas } from '../utils/helpers'

export default {
  name: 'Leaderboard',
  data() {
    return {
      getCurrencySymbol,
      leaderboardTab: 0,
      users: [],
      selection: 0,
      loadingData: false
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    }),
    topThree() {
      if (this.users.length <= 0) return []
      return this.users.slice(0, 3)
    },

    otherUsers() {
      if (this.users.length <= 0) return []
      return this.users.slice(3)
    }
  },
  created() {
    this.getLeaderboard({
      period: this.selection,
      category: this.leaderboardTab
    })
  },
  methods: {
    switchTypeTab(index) {
      this.leaderboardTab = index
    },
    switchSelectionTab(index) {
      this.selection = index
    },
    async getLeaderboard({ period, category }) {
      this.loadingData = true
      const periodText =
        period === 0
          ? 'day'
          : period === 1
          ? 'month'
          : period === 2
          ? 'week'
          : 'day'
      const categoryText = category === 0 ? 'giftcard' : 'coin'
      const typeText = category === 0 || category === 1 ? 'sale' : 'purchase'

      const res = await dataApi.data().getLeaderboard({
        period: periodText,
        category: categoryText,
        type: typeText,
        requester: 'user',
        country: this.user.country
      })

      this.users = res.data.data.leaderboard

      this.loadingData = false
    },
    getColorBg() {
      const colors = [
        'primary',
        'accent',
        'pink',
        'brown',
        'green',
        'purple',
        'yellow',
        'red',
        'indigo',
        'blue',
        'light-blue',
        'teal',
        'cyan',
        'green',
        'light-green',
        'lime',
        'amber',
        'orange',
        'blue-grey'
      ]
      return colors[Math.floor(Math.random() * colors.length)]
    }
  },
  watch: {
    leaderboardTab: function (newValue, oldValue) {
      // 0 - Giftcard
      // 1 - Coin Sale
      // 2 - Coin Purchase
      if (oldValue !== newValue)
        this.getLeaderboard({
          period: this.selection,
          category: this.leaderboardTab
        })
    },
    selection: function (newValue, oldValue) {
      // 0 = Day
      // 1 - Month
      // 2 - Week
      if (oldValue !== newValue)
        this.getLeaderboard({
          period: this.selection,
          category: this.leaderboardTab
        })
    }
  },
  filters: {
    trucateUsername(value) {
      if (!value) return ''
      return value.length > 10
        ? `${value.toString().substring(0, 10)}...`
        : value
    },
    getInitials(value) {
      if (!value) return 'AA'
      return value[0]
    },
    formatAmount(value) {
      if (!value) return 0
      return numberWithCommas(Number.parseFloat(value).toFixed(2))
    }
  }
}
</script>

<style>
.leaderboard-tabs {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #0d01c7;
  border-radius: 40px;
  color: white;
}

.leaderboard-tab {
  font-size: 14px;
  width: 100%;
  padding: 10px;
  border-radius: 40px;
  text-align: center;
  cursor: pointer;
}

.leaderboard-tab.active {
  font-size: 14px;
  background-color: #eabb23;
  color: #0d01c7;
  font-weight: 600;
  transition: 0.7s;
}

.leaderboard-tabs-period {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: white;
  border-radius: 40px;
  color: rgb(193, 193, 193);
}

.leaderboard-tab-period {
  font-size: 14px;
  width: 100%;
  padding: 10px;
  border-radius: 40px;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.leaderboard-tab-period.active {
  font-size: 14px;
  color: #0d01c7;
  font-weight: 600;
  transition: 0.7s;
}

.leading-group {
  position: relative;
  display: flex;
  margin-top: 6rem;
  margin-bottom: 7rem;
}

.leading-1 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
}

.leading-2 {
  position: absolute;
  top: 0;
  left: 20%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.leading-3 {
  position: absolute;
  top: 0;
  left: 80%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.leading-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.leading-tabs .money {
  margin: 0;
  margin-top: 7px;
  color: #0d01c7;
  font-weight: 600;
  padding: 0;
  font-size: 15px;
}

.leading-tabs .name {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  font-size: 12px;
}

.position {
  background: #a9a9a9;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.position_absolute {
  position: absolute;
  top: -15px;
  z-index: 5;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  box-shadow: 0 0 12px rgb(0 0 0 / 20%);
}
</style>
